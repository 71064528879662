<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/pc/index' }">PC网站</el-breadcrumb-item>
      <el-breadcrumb-item>网站数据</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="data-head">
        <div class="domin">huihaometa.com</div>
        <el-button type="primary" round @click="getNewData" :disabled="checkDisable">查询最新状态</el-button>
      </div>
      <div class="data-list">
        <div class="data-item">
          <div class="title"></div>
          <div class="data"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Index',
  data () {
    return {
      checkDisable: false
    }
  },
  created () {
  },
  methods: {
    async getNewData () {
      const instance = axios.create({
        timeout: 1000,
        headers: {
          'Content-Type': 'application/json'
        }
      })
      instance.get('https://apistore.aizhan.com/site/alexainfos/ec673e39803d89d24ddb8197e6c9a2f8', { params: { domains: 'huihaometa.com' } })
        .then((response) => {
          console.log(response)
        })
      // const data = await this.$http.get('https://apistore.aizhan.com/site/alexainfos/ec673e39803d89d24ddb8197e6c9a2f8', { params: { domains: 'huihaometa.com ' }, headers: {} })
      // console.log(data)
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
.page{
  width: 100%;
  height: 100%;
}
.page iframe{
  width: 100%;
  height: 100%;
  border: 0;
}
/deep/.main-container-wrap .header-wrap{
  display: none;
}
/deep/.main-container-wrap{
  min-width: 0!important;
}
/deep/.pusmall .ToolHeader{
  display: none;
}
</style>
